import { default as __nuxt_component_0 } from "/opt/build/repo/components/Common/InfoTooltip.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/Common/TextInfo.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/App/Drawer.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, withCtx as _withCtx, unref as _unref, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "pt-6 border-t relative" };
const _hoisted_2 = { class: "flex justify-between" };
const _hoisted_3 = {
  class: "font-mono flex items-start break-all",
  "data-cy": "trading-page-details-total-text-content"
};
const _hoisted_4 = /* @__PURE__ */ _createElementVNode("span", { class: "mr-1" }, "\u2248", -1);
const _hoisted_5 = { class: "text-gray-500 ml-1 break-normal" };
const _hoisted_6 = { class: "mt-4" };
const _hoisted_7 = {
  key: 0,
  "data-cy": "trading-page-details-liquidation-price-text-content",
  class: "font-mono flex items-start break-all"
};
const _hoisted_8 = { class: "text-gray-500 ml-1 break-normal" };
const _hoisted_9 = {
  key: 1,
  class: "text-gray-500 ml-1"
};
const _hoisted_10 = {
  key: 0,
  "data-cy": "trading-page-details-notional-value-text-content",
  class: "font-mono flex items-start break-all"
};
const _hoisted_11 = { class: "text-gray-500 ml-1 break-normal" };
const _hoisted_12 = {
  key: 1,
  class: "text-gray-500 ml-1"
};
import {
  MarketType
} from "@injectivelabs/sdk-ui-ts";
import { TRADE_FORM_PRICE_ROUNDING_MODE } from "@/app/utils/constants";
export default /* @__PURE__ */ _defineComponent({
  __name: "ConditionalOrderDetails",
  props: {
    orderTypeReduceOnly: Boolean,
    liquidationPrice: {
      type: Object,
      required: true
    },
    market: {
      type: Object,
      required: true
    },
    notionalValue: {
      type: Object,
      required: true
    }
  },
  setup(__props) {
    const props = __props;
    const isBinaryOption = computed(
      () => props.market.subType === MarketType.BinaryOptions
    );
    const { valueToString: notionalValueToFormat } = useBigNumberFormatter(
      computed(() => props.notionalValue),
      {
        decimalPlaces: props.market.priceDecimals
      }
    );
    const { valueToString: liquidationPriceToFormat } = useBigNumberFormatter(
      computed(() => props.liquidationPrice),
      {
        decimalPlaces: props.market.priceDecimals,
        roundingMode: TRADE_FORM_PRICE_ROUNDING_MODE
      }
    );
    return (_ctx, _cache) => {
      const _component_CommonInfoTooltip = __nuxt_component_0;
      const _component_CommonTextInfo = __nuxt_component_1;
      const _component_AppDrawer = __nuxt_component_2;
      return _openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_AppDrawer, null, {
          header: _withCtx(() => [
            _createElementVNode("p", _hoisted_2, [
              _createVNode(_component_CommonTextInfo, {
                title: _ctx.$t("trade.total"),
                lg: ""
              }, {
                context: _withCtx(() => [
                  _createVNode(_component_CommonInfoTooltip, {
                    class: "ml-2",
                    tooltip: _ctx.$t("trade.market_total_tooltip")
                  }, null, 8, ["tooltip"])
                ]),
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_3, [
                    _hoisted_4,
                    _renderSlot(_ctx.$slots, "total"),
                    _createElementVNode("span", _hoisted_5, _toDisplayString(__props.market.quoteToken.symbol), 1)
                  ])
                ]),
                _: 3
              }, 8, ["title"])
            ])
          ]),
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_6, [
              !__props.orderTypeReduceOnly && !_unref(isBinaryOption) ? (_openBlock(), _createBlock(_component_CommonTextInfo, {
                key: 0,
                title: _ctx.$t("trade.liquidation_price"),
                class: "mt-2"
              }, {
                context: _withCtx(() => [
                  _createVNode(_component_CommonInfoTooltip, {
                    class: "ml-2",
                    tooltip: _ctx.$t("trade.liquidation_price_tooltip")
                  }, null, 8, ["tooltip"])
                ]),
                default: _withCtx(() => [
                  __props.liquidationPrice.gt(0) ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
                    _createTextVNode(_toDisplayString(_unref(liquidationPriceToFormat)) + " ", 1),
                    _createElementVNode("span", _hoisted_8, _toDisplayString(__props.market.quoteToken.symbol), 1)
                  ])) : (_openBlock(), _createElementBlock("span", _hoisted_9, " \u2014 "))
                ]),
                _: 1
              }, 8, ["title"])) : _createCommentVNode("", true),
              !__props.orderTypeReduceOnly ? (_openBlock(), _createBlock(_component_CommonTextInfo, {
                key: 1,
                title: _ctx.$t("trade.margin"),
                class: "mt-2"
              }, {
                context: _withCtx(() => [
                  _createVNode(_component_CommonInfoTooltip, {
                    class: "ml-2",
                    tooltip: _ctx.$t("trade.margin_tooltip")
                  }, null, 8, ["tooltip"])
                ]),
                default: _withCtx(() => [
                  __props.notionalValue.gt(0) ? (_openBlock(), _createElementBlock("span", _hoisted_10, [
                    _createTextVNode(_toDisplayString(_unref(notionalValueToFormat)) + " ", 1),
                    _createElementVNode("span", _hoisted_11, _toDisplayString(__props.market.quoteToken.symbol), 1)
                  ])) : (_openBlock(), _createElementBlock("span", _hoisted_12, " \u2014 "))
                ]),
                _: 1
              }, 8, ["title"])) : _createCommentVNode("", true),
              _renderSlot(_ctx.$slots, "makerTakerFeeRate")
            ])
          ]),
          _: 3
        })
      ]);
    };
  }
});
